import * as React from 'react';
const TermsPageEn = () => {
	return (
		<>
			<title>General terms of use for the Smart Kitchen Dock app</title>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<style
				dangerouslySetInnerHTML={{
					__html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:Tahoma;\n\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\np.MsoHeader, li.MsoHeader, div.MsoHeader\n\t{mso-style-link:"Header Char";\n\tmargin:0cm;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\na:link, span.MsoHyperlink\n\t{color:blue;\n\ttext-decoration:underline;}\np.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:0cm;\n\tmargin-left:36.0pt;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\nspan.HeaderChar\n\t{mso-style-name:"Header Char";\n\tmso-style-link:Header;\n\tfont-family:"Calibri",sans-serif;}\n.MsoChpDefault\n\t{font-size:10.0pt;}\n /* Page Definitions */\n @page WordSection1\n\t{size:612.1pt 792.1pt;\n\tmargin:70.9pt 70.9pt 2.0cm 70.9pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
				}}
			/>
			<div className="WordSection1">
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							General terms of use
						</span>
					</b>
				</p>
				<p className="MsoNormal" align="center" style={{ textAlign: 'center' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							for the Smart Kitchen Dock app
						</span>
					</b>
				</p>
				<p className="MsoNormal">
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							1.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Contractual basis{' '}
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Home Connect GmbH, Carl-Wery-Straße 34, 81739 Munich, Germany ("
						<b>Operator</b>") provides you with the Smart Kitchen Dock App free of charge for installation and use on your mobile devices. Further
						information about the Operator can be found in the imprint of the Smart Kitchen Dock App.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						These General Terms of Use ("<b>General Terms of Use</b>") apply between you as a user of the Smart Kitchen Dock app and the operator of
						the Smart Kitchen Dock app. Only the latter becomes your contractual partner. These General Terms of Use become the binding contractual
						basis for the use of the Smart Kitchen Dock App when you click on the corresponding button in the registration process. You can view and
						save the text of these General Terms of Use in the Smart Kitchen Dock app in the{' '}
					</span>
					<span lang="EN-US">"</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Settings" area. The language version of the country from whose app store you downloaded the Smart Kitchen Dock app applies. In countries
						with several national languages, the operator may offer several language versions.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref457208957">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								2.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Conditions of use
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To use the Smart Kitchen Dock app, the following requirements must be met:
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You are a consumer and at least 18 years old,
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You have created both a Home Connect and an Amazon Alexa user account; the separate terms of use and privacy information of the
						respective user account providers apply to both user accounts,
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '1.0cm',
						textAlign: 'justify',
						textIndent: '-18.0pt'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You operate the Smart Kitchen Dock in a country where the operator offers the Smart Kitchen Dock app (list of available countries
						available at https://www.home-connect.com).
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To use the Smart Kitchen Dock app, you need to install it on an Internet-enabled mobile device with a suitable operating system (for a
						list of supported devices, see https://www.home-connect.com) and a Smart Kitchen Dock. The mobile device and the Smart Kitchen Dock must
						be provided by you. The Smart Kitchen Dock app is only compatible with the Smart Kitchen Dock, i.e. it only works with the Smart Kitchen
						Dock. It is not interoperable with other devices (e.g. other Smart Speakers or docking stations).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To set up the Smart Kitchen Dock, the Smart Kitchen Dock must be connected to the mobile device via Bluetooth and the mobile device and
						the Smart Kitchen Dock must be connected to the Wi-Fi network with an appropriate Internet router.&nbsp;{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Once you have set up the Smart Kitchen Dock via the Smart Kitchen Dock app on the mobile end device in accordance with item{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						certain functionalities of the Smart Kitchen Dock (e.g. voice control) can be used even if the Smart Kitchen Dock is not connected to
						the mobile end device. However, an Internet connection of the Smart Kitchen Dock via the Wi-Fi network is required even then.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Any costs incurred for the data connection for the connection of the Smart Kitchen Dock and the mobile end device (also for the download
						of updates of the Smart Kitchen Dock app or the control software of the Smart Kitchen Dock as well as content or the transfer of images)
						shall be borne by you. The operator is not liable for the availability and/or quality of the data connection.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						It is not possible to use the functionalities of the Smart Kitchen Dock and the Smart Kitchen Dock app (in particular access to Alexa
						Skills and remote control and monitoring of home appliances) when the Wi-Fi connection is deactivated or outside of a Wi-Fi network. In
						offline mode, the Smart Kitchen Dock can only be used as a Bluetooth speaker or as a charger (i.e. without Alexa functionality).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						By downloading the App, you certify that you are not a permanent resident of any state subject to U.S. embargo regulations, have not
						been designated by the U.S. government as a terror-supporting state, and are not personally on any U.S. government list of prohibited or
						restricted parties.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '35.45pt',
						textAlign: 'justify'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref50399744">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								3.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Registration and setup process
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Use of the Smart Kitchen Dock app does not require registration with the Smart Kitchen Dock app or any associated data entry. You only
						need to accept these terms of use. By clicking on the corresponding button, the usage agreement for the use of the Smart Kitchen Dock
						app is concluded.
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In order to use the full functionality of the Smart Kitchen Dock app and the Smart Kitchen Dock, however, you must enter the login data
						for your Amazon Alexa and your Home Connect user account in the Smart Kitchen Dock app (for more details, see no.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						). In case of input errors, you can correct these data directly during the input.{' '}
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						By linking to the Home Connect user account, we receive your e-mail address in order to contact you within the scope of this user
						agreement. The email address will not be used for advertising purposes without your prior consent.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Once you have logged in with your Amazon Alexa and your Home Connect user account, the Smart Kitchen Dock will remain connected to your
						Amazon Alexa and your Home Connect user account until you actively disconnect it again via the Smart Kitchen Dock app or you reset the
						Smart Kitchen Dock to factory settings. Removing the mobile device will not disconnect your Amazon Alexa and Home Connect user account.
						Therefore, if the Smart Kitchen Dock is connected via your WiFi network, these accounts can be controlled via any mobile device
						connected to the Smart Kitchen Dock or the Smart Kitchen Dock itself (for resale procedures, see para.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						0
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref75187508">
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								4.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Smart Kitchen Dock app functions and services
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Smart Kitchen Dock App together with the mobile device to be provided by you is used to set up and use the full functionality of the
						Smart Kitchen Dock. After linking the Smart Kitchen Dock app to your Amazon Alexa and Home Connect user account, you can then use Alexa
						Skills via the Smart Kitchen Dock and control Home Connect-enabled home appliances ("<b>Home Appliances</b>") via predefined voice
						commands.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginLeft: '35.4pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The functions provided by the Smart Kitchen Dock depend on the type of home appliance and the country in which the Smart Kitchen Dock
						and the home appliance are operated.&nbsp; If additional functions are provided for certain types of home appliances, additional special
						terms of use and privacy information may apply. If this is the case, we will make these available to you for retrieval before you
						activate the respective function. The additional special terms of use must be accepted separately and will then become part of this
						agreement. Both the additional special terms of use and supplementary privacy information are available in the Smart Kitchen Dock app in
						the 'Settings' area.{' '}
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To use the features provided by the Smart Kitchen Dock and the Smart Kitchen Dock app, information (e.g., your user IDs; requests you
						make; and your control instructions for home appliances) is transmitted via a data connection to servers that are used to process the
						requests ("
						<b>Amazon Server</b>") and to carry out your control instructions ("
						<b>Home Connect Server</b>"). You can find more information about data handling in the Privacy Policy.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The full functionality of the Smart Kitchen Dock is only available to you if (i) you are logged in to your Amazon Alexa and Home Connect
						user account via the Smart Kitchen Dock app, (ii) your Smart Kitchen Dock is connected ("<b>paired</b>") to your mobile device, (iii)
						your Smart Kitchen Dock is associated with your Amazon Alexa and Home Connect user account, and (iv) both your Smart Kitchen Dock has
						established a Bluetooth connection with your mobile device and your mobile device and the Smart Kitchen Dock have established a data
						connection to the Amazon and Home Connect server.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						For security reasons, individual Home Connect functions require manual confirmation or manual activation on the home appliance itself in
						addition to command entry via the Smart Kitchen Dock app.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							5.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Use of the Smart Kitchen Dock by multiple users
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Once the Smart Kitchen Dock has been set up according to numeral{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						via the Smart Kitchen Dock app, certain functions of the Smart Kitchen Dock can also be used without the mobile device. These functions
						are then executed via the stored Amazon Alexa or the stored Home Connect user account and attributed to these accounts. If you want to
						prevent other people who have access to the Smart Kitchen Dock from performing such actions, please log the Smart Kitchen Dock out of
						your Amazon Alexa and Home Connect user account via the Smart Kitchen Dock app.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you let third parties use the Smart Kitchen Dock, you undertake to inform these third parties before use about the purpose and scope
						of the data collection and the further processing and use by the operator as well as Amazon and to obtain the consent of all users to
						this data handling.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							6.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							In-app messages
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You can set on your mobile device whether you want notifications in the form of in-app notifications in the Smart Kitchen Dock app.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you allow push notifications, we can contact you in case of safety notices and product recalls via the Smart Kitchen Dock app.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref457208967">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								7.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Rights of use
							</span>
						</b>
					</a>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Smart Kitchen Dock App and the services and content covered by it are comprehensively copyrighted.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						For the duration of this User Agreement, you are granted a non-exclusive, non-sublicensable, non-transferable and revocable right of
						use, free of charge, to use the Smart Kitchen Dock App and the services and content included therein exclusively for the purposes agreed
						in these General Terms of Use and the Special Terms of Use and in accordance with these Terms of Use and applicable law. In particular,
						unless permitted by mandatory law, you may not copy, edit, revise, modify, decompile (reverse engineer) or transform the Smart Kitchen
						Dock App. The Smart Kitchen Dock App is only compatible with Home Connect enabled home appliances.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							8.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Content use
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you use suggested content (e.g. cooking recipes), please make sure beforehand that this content is suitable for you personally.
						Therefore, for example, only use dishes or suggested ingredients if they do not pose a health risk with regard to your individual health
						condition (e.g. due to allergies). In the case of suggested shelf life/consumption recommendations, please check the food before use to
						ensure that it is indeed still safe for consumption. If you create recipes yourself on your home appliance (e.g. the Cookit), these will
						not be checked by us. Please create these recipes carefully to avoid any possible damage to health or property. Please also check
						suggested programs (e.g. washing or drying programs) again before starting them.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							9.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Availability of functions and services
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Operator shall make reasonable efforts to provide all functionalities and services of the Smart Kitchen Dock App as well as
						integration and coupling with third party services. However, technical difficulties may occur that lead to a temporary interruption of
						availability. You cannot derive any claims against the operator from an interruption in availability.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref458099389">
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								10.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Third-party services
							</span>
						</b>
					</a>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							{' '}
							- especially Amazon Alexa and Home Connect
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Third-party services integrated into the Smart Kitchen Dock app (e.g. Amazon Alexa or Home Connect) require the Smart Kitchen Dock app
						to be paired with your existing third-party user accounts (cf. para.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						4
					</span>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						).{' '}
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						An Internet connection is required for this pairing.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Any special terms of use of the operator applicable to the use of such services may have to be accepted separately before using these
						services. Please note that in addition to the General Terms of Use of the operator and the special terms of use for integrated or
						external services, the terms of use of the third-party provider also apply. The third-party provider is solely responsible for the
						latter.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The coupling with external services (e.g. Amazon Alexa) represents a functionality over which the operator has only limited influence.
						He can therefore not vouch for the availability of these services. As a rule, the operator cannot check the accuracy and completeness of
						the data transmitted by the third-party provider and assumes no liability in this respect.
					</span>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '0cm', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							11.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Software updates
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The full functionality and functional reliability of the Smart Kitchen Dock app and the Smart Kitchen Dock can only be ensured if the
						updates provided for the Smart Kitchen Dock app and the Smart Kitchen Dock are installed promptly. An update of the Smart Kitchen Dock
						app may require you to update the operating system of your mobile device in order to continue using the Smart Kitchen Dock app.{' '}
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Updates of the Smart Kitchen Dock are used, for example, to improve existing functionalities, correct bugs or add further functions.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The first time the Smart Kitchen Dock is connected to the Home Connect server, the software included on the Smart Kitchen Dock is
						automatically updated. Before the next update of your Smart Kitchen Dock, you or - in case of multiple users - any user must confirm the
						download and installation in the Smart Kitchen Dock app. For further updates of the Smart Kitchen Dock, you or - in case of several
						users - any user can choose between different variants (e.g. whether such updates are generally downloaded and installed by default,
						whether this should only be the case for security-relevant updates or whether the download and installation should be decided
						individually for each update). In the case of multiple users, each user can subsequently change this setting in the app at any time
						(also with effect for other users). Depending on the selected setting, you will then be informed via the Smart Kitchen Dock app about
						updates provided for your Smart Kitchen Dock.&nbsp; Only in very special exceptional cases (e.g. in cases where product or data security
						requires it <span style={{ display: 'none' }}>or for certain device types</span>) do we generally reserve the right to directly download
						and install Smart Kitchen Dock updates regardless of the settings. You agree to such automatic update of the Smart Kitchen Dock.
						However, if we are forced to perform such an automatic update, we will inform you about it. During the installation of an update of the
						Smart Kitchen Dock (regardless of the type of update), the Smart Kitchen Dock must not be disconnected from power and the Internet
						connection must not be interrupted.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Operator is not liable for errors of the Smart Kitchen Dock App or the Smart Kitchen Dock and damages resulting from the fact that
						you did not download and/or install provided updates of the App or the Smart Kitchen Dock, or did not download and/or install them
						within a reasonable period of time or did not install them completely, provided that we have informed you about the availability of the
						update and the consequences of a failure to install it and the failure to install the update or the improper installation of the update
						is not due to a defective installation instruction provided to you. You should therefore download and install the provided updates
						without undue delay. We will inform you about the provision of updates for Smart Kitchen Docks in the Smart Kitchen Dock app.
						<a name="_Ref78984274" />
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							12.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Relocation / resale / use by third parties
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you move your Smart Kitchen Dock to a country other than the one in which it was originally commissioned, you are required to change
						the country setting in your Alexa and Home Connect user account accordingly. Please note that using the Smart Kitchen Dock app in a
						different country may be subject to different terms of use and privacy policy. If your country is not listed in the list of supported
						countries, further use of the Smart Kitchen Dock App is not permitted. Use of the Smart Kitchen Dock app in conjunction with a Smart
						Kitchen Dock that is not approved for use in that country is prohibited.
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In case of sale or permanent transfer of your mobile device to a third party, delete the Smart Kitchen Dock app. In case of sale of the
						Smart Kitchen Dock, it is important that you reset the Smart Kitchen Dock to factory settings. In this case, the Smart Kitchen Dock's
						link to your Smart Kitchen Dock app and your Alexa and Home Connect user account will be broken. Otherwise, this relationship may
						continue.{' '}
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you have purchased your Smart Kitchen Dock second-hand, we recommend resetting it to the factory settings again. For more information
						on data protection, please refer to the privacy policy.
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginTop: '0cm',
						marginRight: '0cm',
						marginBottom: '6.0pt',
						marginLeft: '0cm',
						textAlign: 'justify'
					}}
				>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Clear - contrary to the specifications in Fig.{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						and{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						of these General Terms of Use - to allow third parties to use your Smart Kitchen Dock App via the mobile device on which the Smart
						Kitchen Dock App is installed, you are responsible for ensuring that the Smart Kitchen Dock App is used in accordance with these Terms
						of Use and the applicable laws.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref53042386">
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								13.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
							</span>
						</b>
						<b>
							<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Liability
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The operator is liable to you in case of intent, gross negligence and fraudulently concealed defects for all damages caused by the
						operator and its legal representatives and agents unlimited. In the case of slight negligence, the operator is liable to you without
						limitation in the case of injury to life, limb or health. In other respects, the operator is only liable to you if it has breached a
						material contractual obligation, i.e. an obligation whose fulfillment is essential to the proper performance of this contract and on
						whose fulfillment you as a contractual partner regularly rely and may rely. In these cases, liability is limited to compensation for
						foreseeable, typically occurring damage. The strict liability of the operator for damages (§ 536a BGB) for defects existing at the time
						of conclusion of the contract is not applicable according to § 578b para. 1 no. 1 BGB. The liability of the operator according to the
						provisions of the Product Liability Act remains unaffected by the above provisions.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							14.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Warranty
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The statutory warranty rights for digital products apply to the Smart Kitchen Dock app provided by the operator.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							15.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Modification of the content functionalities of the Smart Kitchen Dock App
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ marginLeft: '21.3pt', textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Operator reserves the right to make changes to the Smart Kitchen Dock App that go beyond what is necessary to maintain the
						contractual compliance of the Smart Kitchen Dock App. This may be the case, for example, if (i) adaptation to new technologies or
						product or data security require it; (ii) certain functionalities of the Smart Kitchen Dock App are no longer requested or used by a
						sufficiently large number of customers; (iii) misuse or damage is to be prevented or (iv) these functionalities can no longer be offered
						at economically justifiable expense. The same applies to changes resulting from external circumstances (e.g. discontinuation of or
						changes to the services of partners such as Amazon Alexa; changes in the law or changed market action).{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If a change has a negative impact on your use of the Smart Kitchen Dock App, we will expressly inform you of this within a reasonable
						period of time in advance (in particular about the features and timing of the change). An exception exists in urgent cases where the
						changes serve to prevent misuse or damage, to respond to legal requirements or to ensure the security and functionality of the Smart
						Kitchen Dock App.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If the change impairs your ability to access the Smart Kitchen Dock app or its usability for you to a more than insignificant extent,
						you may terminate the usage agreement for the Smart Kitchen Dock app at any time in accordance with section{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						18
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						{' '}
						free of charge.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							16.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Amendment of the General and Special Terms of Use
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The Operator is entitled to make changes to the General Terms of Use as well as to the Special Terms of Use for other services of the
						Operator under the following conditions for the future:
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We will generally notify you of changes to the respective Terms of Use that affect the contractual relationship no less than six weeks
						in advance of the date on which the change takes effect via the Smart Kitchen Dock app and/or by email. The notice will also include
						information about your right to reject the changes and the resulting consequences. If you do not object to the change within the period
						specified in the notice (regularly 6 weeks), this shall be deemed to be your consent to the change ("fictitious consent"); we will refer
						to this separately in the notice. The fictitious consent does not apply to a change that affects a main service of the user contract, if
						this would result in an unfavorable disproportion between service and consideration to your disadvantage. In case of an objection, the
						usage contract will be continued under the previous conditions. However, the operator is entitled to terminate your user contract as of
						the date the change takes effect.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Editorial changes to these Terms of Use, i.e. changes that do not affect the contractual relationship, such as the correction of
						typographical errors, will be made without notification.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref14357609">
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								17.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								&nbsp;Using the Smart Kitchen Dock app
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You may not use the Smart Kitchen Dock App for unauthorized (e.g. military) purposes. You are not authorized to commercially distribute
						or process the services, content, data and information that you receive in connection with the use of the Smart Kitchen Dock App. The
						operator may initiate the appropriate measures in the event of unauthorized or unauthorized use of the Smart Kitchen Dock App, including
						blocking users.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<a name="_Ref96433692">
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								18.
								<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
							</span>
						</b>
						<b>
							<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
								Term and termination of the contract of use
							</span>
						</b>
					</a>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The user contract runs for an indefinite period. You may terminate the User Agreement at any time. The Operator is entitled to terminate
						the User Agreement with you properly with a notice period of six weeks or shorter in the event that the Operator decides to discontinue
						the operation of the Smart Kitchen Dock App.{' '}
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Otherwise, the right of both contracting parties to terminate the contract for good cause shall remain unaffected.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						An important reason exists for the operator in particular if you violate essential obligations of these General Terms of Use (e.g.
						clauses.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						2
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						,{' '}
					</span>
					<b>
						<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Error! Reference source not found.
						</span>
					</b>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						or{' '}
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						17
					</span>
					<span lang="EN-GB" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						5) or the Special Terms of Use or if your Home Connect or Amazon Alexa user account no longer exists or the Home Connect or Amazon Alexa
						service is discontinued.{' '}
					</span>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In the latter case, logging into the Smart Kitchen Dock app is no longer possible.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							19.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
						</span>
					</b>
					<b>
						<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Right of withdrawal
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="DE" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						As a consumer, i.e. as a natural person who concludes a legal transaction for purposes that can predominantly be attributed neither to
						his commercial nor to his independent professional activity, you are entitled to a right of revocation within fourteen days for all
						chargeable services offered by the operator in the Smart Kitchen Dock app. This right of withdrawal only applies to the respective
						chargeable service offered by the operator in the Smart Kitchen Dock app. The purchase contract for the Smart Kitchen Dock or the Home
						Connect-enabled devices or any existing right of revocation in this context remains unaffected.{' '}
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Cancellation policy
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							&nbsp;
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Right of withdrawal
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						You have the right to revoke this usage agreement for the use of the Smart Kitchen Dock app within fourteen days without giving any
						reason.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The withdrawal period is fourteen days from the date of conclusion of the contract.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To exercise your right of withdrawal, you must inform us (the Home Connect GmbH, Carl-Wery-Straße 34, 81739 Munich, Germany,
						info.de@home-connect.com; +49 (0) 89 / 4590 01) by means of a clear statement (eg a letter sent by mail or e-mail) of your decision to
						revoke this contract. You can use the attached sample withdrawal form for this purpose, but it is not mandatory.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						In order to comply with the withdrawal period, it is sufficient that you send the notification of the exercise of the right of
						withdrawal before the expiry of the withdrawal period.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Consequences of the revocation
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you revoke this user contract, we shall reimburse you all payments we have received from you under this user contract, including
						delivery costs (with the exception of additional costs resulting from the fact that you have chosen a type of delivery other than the
						most favorable standard delivery offered by us), without undue delay and at the latest within fourteen days from the day on which we
						received the notification of your revocation of this contract. For this repayment, we will use the same means of payment that you used
						for the original transaction, unless expressly agreed otherwise with you; in no case will you be charged for this repayment.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If you have requested that the services begin during the withdrawal period, you must pay us a reasonable amount for chargeable services
						that corresponds to the proportion of the chargeable services already provided up to the point in time at which you notify us of the
						exercise of the right of withdrawal with regard to this contract compared to the total scope of the services provided for in the
						contract.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The right of withdrawal shall also expire in the case of a contract for the supply of digital content not on a tangible medium if the
						operator has begun executing the contract after you (i) have expressly consented to the operator beginning to execute the contract
						before the expiry of the withdrawal period and (ii) have confirmed your knowledge that your consent means that you lose your right of
						withdrawal when execution of the contract begins.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Sample cancellation form{' '}
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						(If you want to cancel the contract, please fill out and return this form).
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						To: Home Connect GmbH, Carl-Wery-Straße 34, 81739 Munich, Germany or{' '}
					</span>
					<span lang="EN-US">
						<a href="mailto:info.de@home-connect.com">
							<span style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>info.de@home-connect.com</span>
						</a>
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/provision of the following
						service (*)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-Ordered on (*)/Received on (*)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-Name of the consumer(s)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-Address of the consumer(s)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-Signature of the consumer(s) (only in case of notification on paper)
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						-Date
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						_________
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						(*) Delete as applicable.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						For services of partners, the cancellation policies of the respective partners must be observed.
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							20.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Online dispute resolution platform, participation in dispute resolution proceedings
						</span>
					</b>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The European Commission provides a platform for online dispute resolution (OS). The platform can be reached at{' '}
					</span>
					<span lang="EN-US">
						<a href="https://ec.europa.eu/consumers/odr/">
							<span style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>https://ec.europa.eu/consumers/odr/.</span>
						</a>
					</span>
				</p>
				<p className="MsoNormal" style={{ marginBottom: '6.0pt', textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						We are not obliged to participate in a dispute resolution procedure before a consumer arbitration board and have decided against
						voluntary participation in it. If you have any complaints, grievances, questions or comments, please feel free to contact us using the
						contact details provided in the Smart Kitchen Dock app imprint.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						marginLeft: '21.3pt',
						textAlign: 'justify',
						textIndent: '-21.3pt'
					}}
				>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							21.
							<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span>
						</span>
					</b>
					<b>
						<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
							Applicable Law / Place of Jurisdiction / Severability Clause{' '}
						</span>
					</b>
				</p>
				<p className="MsoListParagraph" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						The mandatory provisions of the law in which you usually reside shall apply. Otherwise, the law of the Federal Republic of Germany shall
						apply. The provisions of the UN Convention on Contracts for the International Sale of Goods shall not apply, irrespective of the
						applicable law. The place of jurisdiction for all disputes arising from or in connection with these General Terms of Use and the Special
						Terms of Use shall be Munich, Germany. Mandatory statutory places of jurisdiction remain unaffected.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						If any provision of these General Terms and Conditions or the Special Terms and Conditions of Use is or becomes invalid, this shall not
						affect the validity of the remaining provisions.
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" style={{ textAlign: 'justify' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						&nbsp;
					</span>
				</p>
				<p className="MsoNormal" align="right" style={{ textAlign: 'right' }}>
					<span lang="EN-US" style={{ fontSize: '10.0pt', fontFamily: '"Arial",sans-serif' }}>
						Version: V1.0.0 September 2022
					</span>
				</p>
			</div>
		</>
	);
};

export default TermsPageEn;
